import { z } from "zod";

import { CurrencyCode } from "@smartrr/shared/currencyCode";
import {
  MoneyV2,
  SellingPlanFixedPricingPolicy,
  SellingPlanInterval,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyPercentageValue,
  SellingPlanRecurringPricingPolicy,
  CurrencyCode as ShopifyCurrencyCode,
} from "@smartrr/shared/shopifyGraphQL/api";
import type { Nullable } from "@smartrr/shared/types";

import { SELLING_PLAN_GROUP_ADDON_TAG, SELLING_PLAN_GROUP_TRENDING_LISTS_TAG } from "../../constants";
import { ISellingPlanGroupProductsAndVariantsEmission } from "../../shopifyGraphQL/sellingPlans";
import { oneLine } from "common-tags";
import { daysInMonthNoLeapYear } from "../../utils/dateUtils";
import { shopifyGidToNumber } from "../../utils/ensureShopifyGid";
import {
  IPurchasable,
  IShopifyProductIdToShopifyVariantIdMap,
  getFilteredShopifyProductIdToShopifyVariantIdMap,
} from "../Purchasable";
import { IShopifyMirroredEntityFields } from "../shared/SharedEntityFields";
import { hasDuplicates } from "../../utils/sellingPlans";

export interface IEnabledShopifyProductIdToShopifyVariantIdMap {
  [productShopifyId: number]: number[];
  [productShopifyId: string]: number[];
}

export interface ISmartrrSellingPlanGroupBase {
  shopifyNumericId: number;
  shopifyId: string;
  name: string;
  appId: string | null;
  description: string | null;
  merchantCode: string;
  options: string[];
  productIds: number[];
  variantIds: number[];
  enabledProductIdToVariantIdMap: IEnabledShopifyProductIdToShopifyVariantIdMap;
  subscriptionCount: number;
  sellingPlanCount: number;
  summary: string | null;
  position: number | null;
}

export interface ISmartrrSellingPlanGroup extends ISmartrrSellingPlanGroupBase {
  sellingPlans: ISmartrrSellingPlan[];
}

export interface ISmartrrAddOnsConfig extends ISmartrrSellingPlanGroupBase {
  sellingPlans: ISmartrrAddOnsConfigSellingPlan[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISmartrrTrendingListsConfig extends ISmartrrAddOnsConfig {}

export interface ISmartrrBundleSearchConfig {
  displayTerm: string;
  searchTag: string;
}

export interface ISmartrrBundleFilter {
  name: string;
  searches: ISmartrrBundleSearchConfig[];
}

export interface ISmartrrBundleCollections {
  title: string;
  handle: string;
}

export interface ISmartrrBundlePacks {
  name: string;
  description: string;
  alert: string;
  collectionString: string;
  minProducts: number;
  maxProducts: number;
  variantSelection: string;
  searchFilterString: string;
  buyWithVariant: string;
}

export interface ISmartrrBundleLineItem {
  id: string;
  quantity: number;
}

export interface ISmartrrBundleConfig {
  name: string;
  slug?: string;
  id?: string;
  searchFilters: ISmartrrBundleFilter[];
  bundleProduct: string;
  packs: ISmartrrBundlePacks[];
  sellingPlanGroupId: string;
  useLineItems: boolean;
  hideBundleItems: boolean;
}

export interface ISmartrrSellingPlanGroupWithEnablementMap extends ISmartrrSellingPlanGroup {
  enablementMap: IShopifyProductIdToShopifyVariantIdMap;
}

export interface IShopifySellingPlanGroupVariant {
  __typename?: string;
  cursor: string;
  node: { __typename?: string; id: string; product: { __typename?: string; id: string } };
}

export enum SellingPlanConfigs {
  ADDON = "addonConfig",
  TRENDING = "trendingConfig",
  SUBSCRIPTION = "sellingPlan",
}

export function filterToAddOnsConfigAndFormat(
  purchasables: IPurchasable[],
  rawAllSellingPlanGroupsEmission: ISellingPlanGroupProductsAndVariantsEmission[]
) {
  const formattedSellingPlanGroups = rawAllSellingPlanGroupsEmission.map(emission =>
    rawFormatSellingPlanGroupsEmission(purchasables, emission)
  );
  return (
    formattedSellingPlanGroups.filter(spgroup => Boolean(spgroup.name.includes(SELLING_PLAN_GROUP_ADDON_TAG))) ??
    []
  ).map(spgroup => formatAddOnsConfig(spgroup));
}

export function filterToTrendingConfigAndFormat(
  purchasables: IPurchasable[],
  rawAllSellingPlanGroupsEmission: ISellingPlanGroupProductsAndVariantsEmission[]
) {
  const formattedSellingPlanGroups = rawAllSellingPlanGroupsEmission.map(emission =>
    rawFormatSellingPlanGroupsEmission(purchasables, emission)
  );
  return (
    formattedSellingPlanGroups.filter(spgroup =>
      Boolean(spgroup.name.includes(SELLING_PLAN_GROUP_TRENDING_LISTS_TAG))
    ) ?? []
  ).map(spgroup => formatAddOnsConfig(spgroup));
}

export function filterToSellingPlansAndFormat(
  purchasables: IPurchasable[],
  rawAllSellingPlanGroupsEmission: ISellingPlanGroupProductsAndVariantsEmission[],
  includeAddons = false
) {
  const formattedSellingPlanGroups = rawAllSellingPlanGroupsEmission.map(emission =>
    rawFormatSellingPlanGroupsEmission(purchasables, emission)
  );

  const filteredSellingPlanGroups = includeAddons
    ? formattedSellingPlanGroups
    : formattedSellingPlanGroups.filter(spgroup => !spgroup.name.includes(SELLING_PLAN_GROUP_ADDON_TAG));

  return filteredSellingPlanGroups.map(spgroup => formatSellingPlanGroups(spgroup));
}

export function formatAddOnsConfig(formattedSellingPlanGroups: ISmartrrSellingPlanGroup): ISmartrrAddOnsConfig {
  return {
    ...formattedSellingPlanGroups,
    sellingPlans: formattedSellingPlanGroups.sellingPlans.map(sellingPlan => ({
      shopifyId: sellingPlan.shopifyId,
      shopifyNumericId: sellingPlan.shopifyNumericId,
      pricingPolicies: sellingPlan.pricingPolicies as SmartrrSellingPlanFixedPricingPolicy[],
    })),
  };
}

export function formatAllEmissions(
  purchasables: IPurchasable[],
  rawSellingPlanGroupsEmission: ISellingPlanGroupProductsAndVariantsEmission[],
  rawAddOnTrendingEmission: ISellingPlanGroupProductsAndVariantsEmission[]
) {
  const formattedPublicSellingPlanGroups = rawSellingPlanGroupsEmission.map(emission =>
    rawFormatSellingPlanGroupsEmission(purchasables, emission)
  );
  const formattedAddOnTrendingSellingPlanGroups = rawAddOnTrendingEmission.map(emission =>
    rawFormatSellingPlanGroupsEmission(purchasables, emission)
  );

  const sellingPlanGroups = (
    formattedPublicSellingPlanGroups.filter(spgroup => !spgroup.name.includes(SELLING_PLAN_GROUP_ADDON_TAG)) ?? []
  ).map(spgroup => formatSellingPlanGroups(spgroup));

  const addOnConfigs = (
    formattedAddOnTrendingSellingPlanGroups.filter(spgroup =>
      Boolean(spgroup.name.includes(SELLING_PLAN_GROUP_ADDON_TAG))
    ) ?? []
  ).map(spgroup => formatSellingPlanGroups(spgroup));

  const trendingConfigs = (
    formattedAddOnTrendingSellingPlanGroups.filter(spgroup =>
      Boolean(spgroup.name.includes(SELLING_PLAN_GROUP_TRENDING_LISTS_TAG))
    ) ?? []
  ).map(spgroup => formatSellingPlanGroups(spgroup));

  return {
    sellingPlanGroups,
    addOnConfigs,
    trendingConfigs,
  };
}

function formatSellingPlanGroups(formattedSellingPlanGroup: ISmartrrSellingPlanGroup): ISmartrrSellingPlanGroup {
  return formattedSellingPlanGroup;
}

function rawFormatSellingPlanGroupsEmission(
  purchasables: IPurchasable[],
  { sellingPlanGroup, sellingPlans, productIds, variantIds }: ISellingPlanGroupProductsAndVariantsEmission
): ISmartrrSellingPlanGroup {
  const shopifyProductIds = (productIds ?? []).map(pid => shopifyGidToNumber(pid));
  const shopifyVariantIds = (variantIds ?? []).map(vid => shopifyGidToNumber(vid));

  return {
    sellingPlanCount: 0,
    subscriptionCount: 0,
    shopifyNumericId: shopifyGidToNumber(sellingPlanGroup.id),
    shopifyId: sellingPlanGroup.id,
    appId: sellingPlanGroup.appId || null,
    name: sellingPlanGroup.name,
    merchantCode: sellingPlanGroup.merchantCode,
    description: sellingPlanGroup.description || null,
    options: sellingPlanGroup.options,
    productIds: shopifyProductIds,
    variantIds: shopifyVariantIds,
    enabledProductIdToVariantIdMap: getFilteredShopifyProductIdToShopifyVariantIdMap(
      purchasables,
      shopifyProductIds,
      shopifyVariantIds
    ),
    summary: sellingPlanGroup.summary || null,
    position: sellingPlanGroup.position ?? null,
    sellingPlans: (sellingPlans ?? []).map(sellingPlan => {
      if (
        sellingPlan.billingPolicy.__typename === "SellingPlanRecurringBillingPolicy" &&
        sellingPlan.deliveryPolicy.__typename === "SellingPlanRecurringDeliveryPolicy"
      ) {
        return {
          shopifyNumericId: shopifyGidToNumber(sellingPlan.id),
          category: sellingPlan.category,
          shopifyId: sellingPlan.id,
          name: sellingPlan.name,
          description: sellingPlan.description || null,
          options: sellingPlan.options,
          recurringDeliveries: sellingPlan.deliveryPolicy.__typename === "SellingPlanRecurringDeliveryPolicy",
          billingPolicy: {
            interval: sellingPlan.billingPolicy.interval,
            intervalCount: sellingPlan.billingPolicy.intervalCount,
            maxCycles: sellingPlan.billingPolicy.maxCycles ?? undefined,
            minCycles: sellingPlan.billingPolicy.minCycles ?? undefined,
          },
          deliveryPolicy: {
            interval: sellingPlan.deliveryPolicy.interval,
            intervalCount: sellingPlan.deliveryPolicy.intervalCount,
          },
          pricingPolicies: sellingPlan.pricingPolicies
            .map(pricingPolicy => {
              switch (pricingPolicy.__typename) {
                case "SellingPlanFixedPricingPolicy": {
                  const adjObj = shopifySellingPlanPricingPolicyToSmartrrSellingPlanPricingPolicy(pricingPolicy);
                  return {
                    ...adjObj,
                    pricingPolicyType: pricingPolicy.__typename,
                  };
                }
                case "SellingPlanRecurringPricingPolicy": {
                  const adjObj = shopifySellingPlanPricingPolicyToSmartrrSellingPlanPricingPolicy(pricingPolicy);
                  return {
                    ...adjObj,
                    pricingPolicyType: pricingPolicy.__typename,
                    afterCycle: pricingPolicy.afterCycle ?? 1,
                  };
                }
                default: {
                  throw new Error("Unexpected pricing policy type");
                }
              }
            })
            .filter((x): x is SmartrrSellingPlanPricingPolicy => x !== null),
        };
      }
      throw new Error(
        `Unexpected billing or delivery policy:  \n` +
          `Billing policy typename: ${sellingPlan.billingPolicy.__typename} \n` +
          `Delivery policy typename: ${sellingPlan.deliveryPolicy.__typename} \n`
      );
    }),
  };
}

// type is a total mess, Shopify type screw-up
function shopifySellingPlanPricingPolicyToSmartrrSellingPlanPricingPolicy(
  pricingPolicy:
    | ({ __typename?: "SellingPlanFixedPricingPolicy" } & Pick<
        SellingPlanFixedPricingPolicy,
        "adjustmentType"
      > & {
          adjustmentValue:
            | ({ __typename?: "MoneyV2" } & Pick<MoneyV2, "amount" | "currencyCode">)
            | ({ __typename?: "SellingPlanPricingPolicyPercentageValue" } & Pick<
                SellingPlanPricingPolicyPercentageValue,
                "percentage"
              >);
        })
    | ({ __typename?: "SellingPlanRecurringPricingPolicy" } & Pick<
        SellingPlanRecurringPricingPolicy,
        "adjustmentType" | "afterCycle"
      > & {
          adjustmentValue:
            | ({ __typename?: "MoneyV2" } & Pick<MoneyV2, "amount" | "currencyCode">)
            | ({ __typename?: "SellingPlanPricingPolicyPercentageValue" } & Pick<
                SellingPlanPricingPolicyPercentageValue,
                "percentage"
              >);
        })
): Pick<SmartrrSellingPlanPricingPolicy, "adjustmentType" | "adjustmentValue"> {
  if (pricingPolicy.adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage) {
    return {
      adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
      adjustmentValue: {
        percentage: (pricingPolicy.adjustmentValue as SellingPlanPricingPolicyPercentageValue).percentage,
      },
    };
  }

  const castedAdjValue = pricingPolicy.adjustmentValue as MoneyV2;
  return {
    adjustmentType: pricingPolicy.adjustmentType,
    adjustmentValue: {
      amount: castedAdjValue.amount,
      currencyCode: castedAdjValue.currencyCode,
    },
  };
}

export interface ISmartrrSellingPlan {
  shopifyNumericId: number;
  shopifyId: string;
  name: string;
  description: string | null;
  options: string[];
  billingPolicy: ISmartrrBillingPolicy;
  deliveryPolicy: ISmartrrDeliveryPolicy;
  recurringDeliveries: boolean;
  pricingPolicies: SmartrrSellingPlanPricingPolicy[];
  category?: SmartrrSellingPlanCategory | null;
}

export interface ISmartrrSellingPlanDBEntity
  extends IShopifyMirroredEntityFields,
    Omit<ISmartrrSellingPlan, "shopifyNumericId" | "shopifyId"> {
  sellingPlanGroupShopifyId: string;
  sellingPlanGroupName: string;
  sellingPlanGroupProductIds: string[];
  sellingPlanGroupVariantIds: string[];
  sellingPlanGroupOptions: string[];
  sellingPlanGroupEnabledProductIdToVariantIdMap: IEnabledShopifyProductIdToShopifyVariantIdMap;
  isPrepaid?: boolean;
  isSequential?: boolean;
  isAdvancedSequential?: boolean;
  isTerminal?: boolean;
  isBundle?: boolean;
  bundleProductVariantIds?: string[];
}

export interface ISmartrrAddOnsConfigSellingPlan {
  shopifyNumericId: number;
  shopifyId: string;
  pricingPolicies: SmartrrSellingPlanFixedPricingPolicy[];
}

export interface SmartrrSellingPlanAnchor {
  day: number;
  month?: number;
  type: "WEEKDAY" | "MONTHDAY" | "YEARDAY";
}

export interface ISmartrrBillingPolicy {
  interval: `${SellingPlanInterval}` | SellingPlanInterval;
  intervalCount: number;
  maxCycles?: Nullable<number>;
  minCycles?: Nullable<number>;
  anchors?: SmartrrSellingPlanAnchor[];
}

export interface ISmartrrDeliveryPolicy {
  interval: "DAY" | "WEEK" | "MONTH" | "YEAR";
  intervalCount: number;
  anchors?: SmartrrSellingPlanAnchor[];
  cutoff?: number;
  preAnchorBehavior?: "ASAP" | "NEXT";
}

export type SmartrrSellingPlanCategory = "OTHER" | "PRE_ORDER" | "SUBSCRIPTION" | "TRY_BEFORE_YOU_BUY";

export type SmartrrSellingPlanPricingPolicy =
  | SmartrrSellingPlanFixedPricingPolicy
  | SmartrrSellingPlanRecurringPricingPolicy;

export type SmartrrSellingPlanFixedPricingPolicy = SmartrrSellingPlanPricingPolicyAdjustmentType & {
  pricingPolicyType: "SellingPlanFixedPricingPolicy";
};

export type SmartrrSellingPlanRecurringPricingPolicy = SmartrrSellingPlanPricingPolicyAdjustmentType & {
  pricingPolicyType: "SellingPlanRecurringPricingPolicy";
  /** Cycle after which this pricing policy applies. */
  afterCycle: number;
};

type SmartrrSellingPlanPricingPolicyAdjustmentType =
  | SmartrrSellingPlanPricingPolicyAdjustmentTypePercentage
  | SmartrrSellingPlanPricingPolicyAdjustmentTypeFixedAmount
  | SmartrrSellingPlanPricingPolicyAdjustmentTypePrice;

export interface SmartrrSellingPlanPricingPolicyAdjustmentTypePercentage {
  /** The price adjustment type. */
  adjustmentType: `${SellingPlanPricingPolicyAdjustmentType.Percentage}`;
  /** The price adjustment value. */
  adjustmentValue: {
    percentage: number;
  };
}

export interface SmartrrSellingPlanPricingPolicyAdjustmentTypeFixedAmount {
  /** The price adjustment type. */
  adjustmentType: `${SellingPlanPricingPolicyAdjustmentType.FixedAmount}`;
  /** The price adjustment value. */
  adjustmentValue: {
    amount: any;
    /** Currency of the money. */
    currencyCode: CurrencyCode;
  };
}

export interface SmartrrSellingPlanPricingPolicyAdjustmentTypePrice {
  /** The price adjustment type. */
  adjustmentType: `${SellingPlanPricingPolicyAdjustmentType.Price}`;
  /** The price adjustment value. */
  adjustmentValue: {
    amount: any;
    /** Currency of the money. */
    currencyCode: CurrencyCode;
  };

  computedPrice?: {
    amount: any;
    /** Currency of the money. */
    currencyCode: CurrencyCode;
  };
}

export type ISellingPlanGroupInputForZod = Omit<
  ISellingPlanGroupCreateWithId,
  "sellingPlans" | "productIds" | "productVariantIds" | "createdDate"
>;

export type ISellingPlanGroupCreateWithId = ISellingPlanGroupCreate & {
  id?: string;
  shopifyId?: string | null;
};
export type ISellingPlanFromZodWithId = ISellingPlanFromZod & {
  createdDate?: string;
  id?: string;
  shopifyId?: string | null;
};

export type ISellingPlanGroupUpdate = z.input<typeof sellingPlanGroupUpdateSchema>;
export type SellingPlanGroupValidationErrors =
  | SellingPlanGroupCreateValidationErrors
  | SellingPlanGroupUpdateValidationErrors;
export type SellingPlanGroupCreateValidationErrors = z.inferFormattedError<typeof sellingPlanGroupCreateSchema>;
export type SellingPlanGroupUpdateValidationErrors = z.inferFormattedError<typeof sellingPlanGroupUpdateSchema>;
export type ISellingPlanGroupCreate = z.input<typeof sellingPlanGroupCreateSchema>;
export type ISellingPlanFromZod = z.input<typeof sellingPlanSchema>;
export type ISellingPlanPricingPolicyFromZod = z.input<typeof pricingPolicySchema>;
export type ISellingPlanFixedPricingPolicyFromZod = z.input<typeof pricingPolicyFixedSchema>;
export type ISellingPlanRecurringPricingPolicyFromZod = z.input<typeof pricingPolicyRecurringSchema>;
export type IPricingPolicyAdjustmentFromZod = z.input<typeof pricingPolicyAdjustmentSchema>;
export type AddonSellingPlanGroupUpdateParams = z.input<typeof addonSellingPlanGroupUpdateSchema>;

export const anchorSchema = z
  .object({
    day: z.number().gt(0),
    month: z.number().gte(1).lte(12).optional(),
    type: z.enum(["WEEKDAY", "MONTHDAY", "YEARDAY"]),
  })
  .superRefine((data, ctx) => {
    if (data.type === "WEEKDAY" && (data.day < 1 || data.day > 7)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "day must be between 1 and 7 for WEEKDAY type",
        path: ["day"],
      });
    }

    if (data.type === "MONTHDAY" && (data.day < 1 || data.day > 31)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "day must be between 1 and 31 for MONTHDAY type",
        path: ["day"],
      });
    }

    if (data.type === "YEARDAY" && !data.month) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "month must be provided for YEARDAY type",
        path: ["month"],
      });
    }

    if (data.type === "YEARDAY" && data.month) {
      if (data.month < 1 || data.month > 12) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "month must be between 1 and 12 for YEARDAY type",
          path: ["month"],
        });
      }

      const maxDay = daysInMonthNoLeapYear(data.month);
      if (data.day < 1 || data.day > maxDay) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: oneLine`
            day must be between 1 and ${maxDay} (inclusive)
            for YEARDAY type with month ${data.month}
          `,
          path: ["day"],
        });
      }
    }
  });

export const pricingPolicyAdjustmentSchema = z.union([
  z.object({
    adjustmentType: z.literal("PERCENTAGE"),
    adjustmentValue: z.object({
      percentage: z.number().gte(0).lte(100),
    }),
  }),
  z.object({
    adjustmentType: z.literal("FIXED_AMOUNT"),
    adjustmentValue: z.object({
      amount: z.number().gte(0),
      currencyCode: z.nativeEnum(ShopifyCurrencyCode),
    }),
  }),
  z.object({
    adjustmentType: z.literal("PRICE"),
    adjustmentValue: z.object({
      amount: z.number().gte(0),
      currencyCode: z.nativeEnum(ShopifyCurrencyCode),
    }),
  }),
]);

export const pricingPolicyFixedSchema = z.object({
  fixed: pricingPolicyAdjustmentSchema,
});

export const pricingPolicyRecurringSchema = z.object({
  recurring: pricingPolicyAdjustmentSchema.and(
    z.object({
      afterCycle: z.number().gte(1),
    })
  ),
});

export const pricingPolicySchema = z.union([pricingPolicyFixedSchema, pricingPolicyRecurringSchema]);

export const billingPolicySchema = z.object({
  recurring: z
    .object({
      interval: z.enum(["DAY", "WEEK", "MONTH", "YEAR"]),
      intervalCount: z.number().gt(0).default(1),
      anchors: z.array(anchorSchema).default([]),
      minCycles: z.number().gt(0).nullish(),
      maxCycles: z.number().gt(0).nullish(),
    })
    .refine(
      data => {
        return !data.minCycles || !data.maxCycles || data.minCycles <= data.maxCycles;
      },
      {
        message: "When both minCycles and maxCycles are set, minCycles must be less than or equal to maxCycles",
        path: ["minCycles"],
      }
    ),
});

export const deliveryPolicySchema = z.object({
  recurring: z.object({
    interval: z.enum(["DAY", "WEEK", "MONTH", "YEAR"]),
    intervalCount: z.number().gt(0).default(1),
    anchors: z.array(anchorSchema).default([]),
    cutoff: z.number().gte(0).optional(),
    preAnchorBehavior: z.enum(["ASAP", "NEXT"]).default("ASAP"),
  }),
});

export const sellingPlanSchema = z
  .object({
    cartCheckoutLabel: z.string().trim().min(1, "Cart Checkout Label must not be empty"), // Shopify: name
    displayLabel: z.string().trim().min(1, "Display Label must not be empty"), // Shopify: options
    billingPolicy: billingPolicySchema,
    deliveryPolicy: deliveryPolicySchema,
    pricingPolicies: z.array(pricingPolicySchema).min(1).max(2),
  })
  .refine(
    data => {
      return data.billingPolicy.recurring.interval === data.deliveryPolicy.recurring.interval;
    },
    {
      message: "Billing Policy interval must match Delivery Policy interval",
      path: ["billingPolicy", "recurring", "interval"],
    }
  )
  .refine(
    data => {
      return data.billingPolicy.recurring.intervalCount % data.deliveryPolicy.recurring.intervalCount === 0;
    },
    {
      message: "Billing Policy intervalCount must be a multiple of Delivery Policy intervalCount",
      path: ["billingPolicy", "recurring", "intervalCount"],
    }
  );

export const sellingPlanGroupCreateSchema = z
  .object({
    storefrontLabel: z.string().trim().min(1, "Storefront Label must not be empty"), // "name" in Shopify
    adminLabel: z.string().trim(), // "merchantCode" in Shopify
    optionLabel: z.string().trim().min(1, "Option Label must not be empty"), // "options" in Shopify
    productIds: z.array(z.string().trim().regex(/^\d+$/)).default([]),
    productVariantIds: z.array(z.string().trim().regex(/^\d+$/)).default([]),
    sellingPlans: z.array(sellingPlanSchema).min(1),
  })
  .refine(
    data => {
      // check for duplicate cart & checkout label names
      const cartCheckoutLabels = data.sellingPlans?.map(sp => sp.cartCheckoutLabel);

      if (!cartCheckoutLabels) {
        return true;
      }
      return !hasDuplicates(cartCheckoutLabels);
    },
    {
      message: "Duplicate Cart & Checkout labels are not allowed inside the same group",
      path: ["sellingPlans"],
    }
  )
  .refine(
    data => {
      // check for duplicate display label names
      const displayLabels = data.sellingPlans?.map(sp => sp.displayLabel);

      if (!displayLabels) {
        return true;
      }

      return !hasDuplicates(displayLabels);
    },
    {
      message: "Duplicate display labels are not allowed inside the same group",
      path: ["sellingPlans"],
    }
  );

export const sellingPlanUpdateSchema = sellingPlanSchema.and(
  z.object({
    id: z.string().uuid().optional(),
  })
);

export const sellingPlanGroupUpdateSchema = z
  .object({
    storefrontLabel: z.string().trim().min(1, "Storefront Label must not be empty"), // "name" in Shopify
    adminLabel: z.string().trim(), // "merchantCode" in Shopify
    optionLabel: z.string().trim().min(1, "Option Label must not be empty"), // "options" in Shopify
    productIds: z.array(z.string().trim().regex(/^\d+$/)),
    productVariantIds: z.array(z.string().trim().regex(/^\d+$/)),
    sellingPlans: z.array(sellingPlanUpdateSchema).min(1, "Selling Plans must not be empty"),
  })
  .partial()
  .refine(
    data => {
      // check for duplicate cart & checkout label names
      const cartCheckoutLabels = data.sellingPlans?.map(sp => sp.cartCheckoutLabel);

      if (!cartCheckoutLabels) {
        return true;
      }
      return !hasDuplicates(cartCheckoutLabels);
    },
    {
      message: "Duplicate Cart & Checkout labels are not allowed inside the same group",
      path: ["sellingPlans"],
    }
  )
  .refine(
    data => {
      // check for duplicate display label names
      const displayLabels = data.sellingPlans?.map(sp => sp.displayLabel);

      if (!displayLabels) {
        return true;
      }

      return !hasDuplicates(displayLabels);
    },
    {
      message: "Duplicate display labels are not allowed inside the same group",
      path: ["sellingPlans"],
    }
  )
  .refine(data => Object.values(data).some(v => v !== undefined), {
    message: "At least one field must be set",
  });

export const addonSellingPlanUpdateSchema = z.object({
  billingPolicy: billingPolicySchema,
  deliveryPolicy: deliveryPolicySchema,
  pricingPolicies: z.array(pricingPolicySchema).min(1).max(2),
});

export const addonSellingPlanGroupUpdateSchema = z
  .object({
    productIds: z.array(z.string().trim().regex(/^\d+$/)),
    productVariantIds: z.array(z.string().trim().regex(/^\d+$/)),
    sellingPlans: z
      .array(
        addonSellingPlanUpdateSchema.and(
          z.object({
            id: z.string().trim().uuid().optional(),
          })
        )
      )
      .length(1),
  })
  .partial()
  .required({ sellingPlans: true });

export const SellingPlanApiResponseSchema = z.object({
  id: z.string().uuid(),
  shopifyId: z.string().nullable(),
  createdDate: z.string(),
  updatedDate: z.string(),
  cartCheckoutLabel: z.string(),
  displayLabel: z.string(),
  billingPolicy: z.object({
    recurring: z.object({
      interval: z.enum(["DAY", "WEEK", "MONTH", "YEAR"]),
      intervalCount: z.number().gt(0),
      anchors: z.array(anchorSchema),
      minCycles: z.number().gt(0).nullish(),
      maxCycles: z.number().gt(0).nullish(),
    }),
  }),
  deliveryPolicy: z.object({
    recurring: z.object({
      interval: z.enum(["DAY", "WEEK", "MONTH", "YEAR"]),
      intervalCount: z.number().gt(0),
      anchors: z.array(anchorSchema),
      cutoff: z.number().gte(0).optional(),
      preAnchorBehavior: z.enum(["ASAP", "NEXT"]),
    }),
  }),
  pricingPolicies: z.array(pricingPolicySchema),
  isPrepaid: z.boolean(),
  isSequential: z.boolean(),
  isTerminal: z.boolean(),
  isBundle: z.boolean(),
  isAdvancedSequential: z.boolean(),
  recurringDeliveries: z.boolean(), // what is this?
});

export const SellingPlanGroupApiResponseSchema = z.object({
  id: z.string().uuid(),
  shopifyId: z.string().nullable(),
  createdDate: z.string(),
  updatedDate: z.string(),
  storefrontLabel: z.string(),
  adminLabel: z.string(),
  optionLabel: z.string(),
  productIds: z.array(z.string()),
  productVariantIds: z.array(z.string()),
  isAddon: z.boolean(),
  isTrendingList: z.boolean(),
  sellingPlans: z.array(SellingPlanApiResponseSchema),
});

export type SellingPlanGroupApiResponse = z.input<typeof SellingPlanGroupApiResponseSchema>;
